.header {
  background: rgba(43, 41, 47, .93);
  backdrop-filter: blur(5px);
  height: 80px;
  position: sticky;
  top: 0;
  z-index: 50;

  .container {
    height: 100%;
  }
}

.header-items {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;

  > a {
    display: flex;
    align-items: center;
    height: 100%;

    &.hover-able-logo {
      transition: transform 0.1s ease-out;

      &:hover {
        transform: scale(1.2);
      }

      &:active {
        transform: scale(1);
      }
    }
  }

  nav {
    display: flex;
    gap: 30px;
    align-items: center;
    height: 100%;

    a {
      color: var(--gray);
      text-decoration: none;
      font-size: 1rem;
      display: flex;
      align-items: center;
      gap: 7px;
      height: 100%;
      //border-bottom: 2px solid transparent;
      transition: all 0.2s ease-in-out 0s;

      svg {
        color: var(--gray-dark);
        width: 28px;
        height: 28px;
        transition: color 0.2s ease-in-out 0s;
      }

      &:hover,
      &.active {
        color: var(--main2);
        //border-bottom: 2px solid var(--main2);
        //text-decoration: underline;
        //text-decoration-thickness: 2px;
        //text-underline-offset: 5px;

        svg {
          color: var(--main2);
        }
      }

      &:active {
        text-decoration: none;
        color: var(--main1);
        //border-bottom: 2px solid var(--main1);

        svg {
          color: var(--main1);
        }
      }
    }
  }
}

.header-actions {
  display: flex;
  max-width: 420px;
  width: 100%;
  align-items: center;
  gap: 20px;
}

.add-btn {
  border: none;
  background: var(--gradient);
  cursor: pointer;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  transition: transform 0.1s ease-out;

  &:hover {
    transform: scale(1.2);
  }

  &:active {
    transform: scale(1);
  }

  svg {
    color: var(--light);
    width: 32px;
    height: 32px;
  }
}

.edit-btn {
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.1s ease-out;

  &:hover {
    transform: scale(1.2);
  }

  &:active {
    transform: scale(1);
  }

  svg {
    color: var(--light);
    width: 32px;
    height: 32px;
  }
}

.search-container {
  display: flex;
  width: 100%;
  position: relative;
  flex-shrink: 0;

  svg {
    position: absolute;
    top: 7px;
    left: 10px;
    color: var(--gray-dark);
    width: 24px;
    height: 24px;
  }

  input {
    color: var(--gray);
    outline: none;
    width: 100%;
    border: none;
    background: var(--darker);
    padding: 8px 15px 8px 45px;
    font-size: 1rem;
    transition: box-shadow 0.2s ease-in-out 0s;

    &:hover {
      box-shadow: 0 0 2px 2px var(--main2);
    }

    &:focus {
      box-shadow: 0 0 2px 2px var(--main1);
    }
  }
}
