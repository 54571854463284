.sidebar-content {
  display: flex;
  flex-direction: column;

  form {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }

  .p-inputtextarea {
    width: 100%;
    min-height: 120px !important;
  }

  form > div {
    gap: 20px;
    display: flex;

    label {
      width: 20%;
    }
  }
}

.selectable-images {
  display: flex;
  gap: 20px;
  margin: 0 0 50px 0;

  img {
    max-height: 160px;
    width: auto;
  }
}

.selected-image {
  box-shadow: 0 0 3px 8px var(--main2);
}

.parse-container {
  display: flex;
  border-bottom: 1px solid var(--gray);
  padding: 0 0 50px 0;
  margin: 0 0 50px 0;
}
