main {
  padding: 0 0 100px 0;
}

.hide-content {
  opacity: 0;
}

.content-header {
  display: flex;
  margin: 50px 0;
  transition: opacity 0.2s ease-in-out 0s;
  justify-content: space-between;
  align-items: center;

  h2 {
    color: var(--gray);
    font-weight: 400;
    font-size: 2rem;
    display: flex;
    align-items: center;
    gap: 15px;


    svg {
      color: var(--gray-dark);
    }
  }
}

.content-header-actions {
  display: flex;
  gap: 20px;
}

.content-header-action {
  display: flex;

  label {
    color: var(--gray-dark);
    font-size: 1rem;
    padding: 0 0 0 10px;
  }
}

.covers {
  display: grid;
  grid-template-columns: repeat(auto-fit, 240px);
  grid-gap: 1.5rem;
  transition: opacity 0.2s ease-in-out 0s;
}

.series-container {
  display: flex;
  flex-direction: column;
  max-width: 240px;
}

.cover-container {
  display: flex;
  /*max-width: 240px;*/
  max-height: 360px;
  outline: none;
  position: relative;

  img {
    object-fit: cover;
    transition: box-shadow 0.2s ease-in-out 0s;
  }

  &:hover {
    .cover-info {
      opacity: 1;
    }

    img {
      box-shadow: 0 0 3px 8px var(--main2);
    }
  }

  &:active {
    img {
      box-shadow: 0 0 3px 8px var(--main1);
    }
  }
}

.cover-info {
  position: absolute;
  bottom: 10px;
  left: 10px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out 0s;
  gap: 5px;
  display: flex;
  align-items: center;

  &.on-top {
    top: 10px;
    bottom: unset;
    opacity: 1;

    span {
      background: var(--main2);

      svg {
        color: var(--light);
      }

      &.background-alert {
        background: var(--alert);
      }
    }
  }

  span {
    background: var(--dark);
    color: var(--light);
    font-size: 0.9rem;
    text-transform: uppercase;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 5px;

    svg {
      width: 24px !important;
      height: 24px !important;
      color: var(--gray);
    }
  }
}

.series-info-container {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}

.series-name {
  color: var(--light);
  font-size: 1.1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  a {
    text-decoration: none;
    color: var(--light);
    transition: color 0.2s ease-in-out 0s;

    &:hover {
      color: var(--main2);
    }

    &:active {
      color: var(--main1);
    }
  }
}

.series-info {
  color: var(--gray);
  font-size: 0.9rem;
  padding: 5px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  a {
    color: var(--gray);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 3px;
    }

    &:active {
      text-decoration: none;
    }
  }
}

.no-content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 200px 0 0 0;

  span {
    color: var(--gray-dark);
    font-size: 2rem;
  }
}

.index {
  display: flex;
  flex-flow: wrap;
  gap: 50px;
  transition: opacity 0.2s ease-in-out 0s;
}

.index-group {
  display: flex;
  flex-direction: column;
  width: 200px;

  h2 {
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--light);
  }

  .index-item {
    display: flex;
    flex-direction: column;
    margin: 0 0 10px 0;

    &:last-child {
      margin: 0;
    }
  }

  span {
    color: var(--gray-dark);
  }

  a {
    color: var(--gray-dark);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 3px;
    }

    &:active {
      text-decoration: none;
    }
  }
}

.details-header {
  background: var(--gradient);
  padding: 150px 0 50px 0;
  position: relative;
  transition: opacity 0.2s ease-in-out 0s;
  margin: -80px 0 0 0;

  h2 {
    font-weight: 400;
    font-size: 3rem;
    color: var(--white);
    margin: 0;
    display: flex;
    align-items: center;
    gap: 20px;
  }

  p {
    color: var(--light);
    font-size: 1.1rem;
    line-height: 1.8rem;
    opacity: .9;
    margin: 20px 0 0 0;
    white-space: pre-wrap;
  }

  .container {
    z-index: 20;
    position: relative;
  }
}

.details-info-container {
  display: flex;
  margin: 40px 0 0 0;
}

.details-info {
  display: flex;
  gap: 10px;
}

.details-info-label {
  color: var(--light);
  font-size: 1.25rem;
}

.details-info-value {
  background: var(--dark);
  color: var(--light);
  text-transform: uppercase;
  font-size: 0.9rem;
  padding: 5px 10px;
  align-items: center;
  display: flex;
  gap: 5px;
  text-decoration: none;

  @at-root {
    a#{&} {
      &:hover {
        background: var(--main2);

        svg {
          color: var(--light);
        }
      }

      &:active {
        background: var(--main1);
      }
    }
  }

  svg {
    width: 24px;
    height: 24px;
    color: var(--gray);
  }

  &.highlight-main1 {
    background: var(--main1);

    svg {
      color: var(--light);
    }
  }

  &.highlight-main2 {
    background: var(--main2);

    svg {
      color: var(--light);
    }
  }

  &.highlight-alert {
    background: var(--alert);

    svg {
      color: var(--light);
    }
  }

  &.highlight-simple {
    background: transparent;
    border: 1px solid var(--white);
  }
}

.details-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0.2;
  filter: grayscale(100%) blur(10px);
}
