:root {
  --main1: #9424fd;
  --main2: #226bfd;
  --alert: #eb3d3d;
  --dark: #15141A;
  --darker: #08080b;
  --dark-lighter: #1f1d23;
  --white: #fff;
  --light: #e3e3e3;
  --gray: #929292;
  --gray-dark: #626262;
  --gradient: linear-gradient(
      90deg,
      rgba(159, 30, 253, 1) 0%,
      rgba(33, 108, 253, 1) 100%
  );

  --max-width-container: 1600px;
}

.test {
  color: #fff;
  font-size: 42px;
  position: sticky;
  top: 0;
  z-index: 10000;
}

@import "primeng";
@import "sidebar";
@import "content";

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: var(--dark);
  overflow-y: scroll;
}

* {
  font-family: 'Open Sans', sans-serif;
}

.container {
  width: 100%;
  max-width: var(--max-width-container);
  padding: 0 20px;
  margin: 0 auto;
}

.loading-container {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
}

.loading-logo {
  animation: moveUpDown .4s infinite alternate;
}

@keyframes moveUpDown {
  0% { transform: translateY(0)}
  100% { transform: translateY(-20px)}
}
