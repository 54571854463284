.p-sidebar {
  background: var(--dark);
  box-shadow: none;
  border-right: 5px solid var(--main2);
  opacity: .95;
}

.p-sidebar-close {
  position: absolute;
  top: 20px;
  right: -55px;
  border-radius: 0 !important;
  background: var(--main2) !important;
  box-shadow: none !important;
  width: 50px !important;
  height: 50px !important;

  span {
    color: var(--light);
  }
}

.p-sidebar-content {
  padding: 20px !important;
}

.p-inputtext {
  border-radius: 0 !important;
  background: var(--gray);
  border: none;
  box-shadow: none !important;
  color: white;
}

.p-dropdown {
  border-radius: 0 !important;
  background: var(--gray);
  border: none;
  box-shadow: none !important;
  color: white;
}

.p-checkbox {
}

.p-checkbox .p-checkbox-box {
  background: transparent;
  border-radius: 0;
  border: 2px solid var(--gray-dark);
  transition: all 0.2s ease-in-out 0s;
  box-shadow: none !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  //box-shadow: 0 0 3px 3px var(--main2);
  border-color: var(--main2);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:active {
  //box-shadow: 0 0 3px 3px var(--main1);
  border-color: var(--main1);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border-color: var(--main2);
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--main2);
  background: var(--main2);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: var(--main2);
  background: var(--main2);
}
